<template>
  <div class="d-flex justify-center align-center" style="height: 80%">
    <v-progress-circular
      :size="100"
      :width="5"
      color="rgb(0, 48, 143)"
      indeterminate
    >
    </v-progress-circular>
  </div>
</template>
<script>
export default {
  name: "Loader",
};
</script>
