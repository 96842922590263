import config from "@/config";
import axios from "axios";

const airport = axios.create({ baseURL: config.airport.url });
const insuide = axios.create({ baseURL: config.insuide.url });
const weather = axios.create({ baseURL: config.weather.url });
const parking = axios.create({ baseURL: config.parking.url });
const poi = axios.create({ baseURL: config.poi.url });

export default {
  airport,
  insuide,
  weather,
  parking,
  poi,
};
