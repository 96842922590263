import event from "../event";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    language: null,
    notice: {
      content: null,
      url: null,
    },
    fullDate: null,
    halfDate: null,
    loader: false,
    search: null,
    airline: null,
    flight: null,
  },
  mutations: {
    onUpdateLanguage(state, payload) {
      state.language = payload;
    },
    onUpdateHeaderNotice(state, payload) {
      state.notice = payload;
    },
    onSetCurrentDate(state, payload) {
      let date = new Date();
      let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
      let am_pm = date.getHours() >= 12 ? "PM" : "AM";
      hours = hours < 10 ? "0" + hours : hours;
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;

      let minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

      state.fullDate =
        date.getDate() +
        ":" +
        month +
        ":" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        minutes;
      state.halfDate = hours + ":" + minutes + " " + am_pm;
    },
    onUpdateLoader(state, payload) {
      state.loader = payload;
      event.$emit("onUpdateLoader", payload);
    },
    onUpdateFlightSearch(state, payload) {
      state.search = payload;
    },
    onUpdateAirline(state, payload) {
      state.airline = payload;
    },
    onUpdateFlight(state, payload) {
      state.flight = payload;
    },
  },
  actions: {
    onUpdateLanguage(context, payload) {
      context.commit("onUpdateLanguage", payload);
    },
    onUpdateHeaderNotice(context, payload) {
      context.commit("onUpdateHeaderNotice", payload);
    },
    onSetCurrentDate(context, payload) {
      context.commit("onSetCurrentDate", payload);
    },
    onUpdateLoader(context, payload) {
      context.commit("onUpdateLoader", payload);
    },
    onUpdateFlightSearch(context, payload) {
      context.commit("onUpdateFlightSearch", payload);
    },
    onUpdateAirline(context, payload) {
      context.commit("onUpdateAirline", payload);
    },
    onUpdateFlight(context, payload) {
      context.commit("onUpdateFlight", payload);
    },
  },
  getters: {
    language(state) {
      return state.language;
    },
    notice(state) {
      return state.notice;
    },
    current(state) {
      return {
        fullDate: state.fullDate,
        halfDate: state.halfDate,
      };
    },
    loader(state) {
      return state.loader;
    },
    search(state) {
      return state.search;
    },
    airline(state) {
      return state.airline;
    },
    flight(state) {
      return state.flight;
    },
  },
});

export default store;
