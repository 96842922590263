<template>
  <div>
    <!-- Top Header Section -->
    <div style="background-color: #00308f" class="firstHeader">
      <v-container
        fluid
        class="pa-0 top-header"
        :class="{ fullwidth: $vuetify.breakpoint.mdAndDown }"
      >
        <v-app-bar
          height="40px"
          color="#00308F"
          dense
          dark
          flat
          class="appbar-content"
        >
          <a
            :href="`${notice?.url}`"
            style="color: #fff"
            v-if="notice?.content"
          >
            <span :class="$vuetify.breakpoint.smAndDown ? 'ml-0' : 'ml-6'"
              ><span
                class="d-inline-block"
                :class="{ 'text-truncate': $vuetify.breakpoint.smAndDown }"
                >{{ notice?.content }}</span
              >
              <v-icon class="ml-4">mdi-chevron-right</v-icon>
            </span>
          </a>
          <v-spacer></v-spacer>
          <span class="hidden-md-and-down">
            <v-icon>mdi-cloud-outline</v-icon>
            <span class="mr-4 ml-2"
              >{{ weather.temperature }} °C {{ weather.description }}</span
            >
            <v-icon>mdi-clock</v-icon>
            <span class="mr-4 ml-2">IST {{ current.halfDate }}</span>
          </span>
          <select
            v-model="language"
            :required="true"
            @change="onChangeLanguage"
            class="lang-Drop"
          >
            <option value="AR">Arabic</option>
            <option value="ZH">Chinese (Mandarin)</option>
            <option value="EN">English</option>
            <option value="FR">French</option>
            <option value="HI">Hindi</option>
            <option value="RU">Russian</option>
            <option value="ES">Spanish</option>
            <option value="TA">Tamil</option>
          </select>
        </v-app-bar>
      </v-container>
    </div>
    <!-- Top Header Section -->

    <!-- Menu Section -->
    <v-container fluid class="top-header">
      <v-toolbar dense height="65px" flat class="secondHeader">
        <v-toolbar-title :class="{ 'logo-shape': $vuetify.breakpoint.mdAndUp }">
          <router-link to="/">
            <v-img
              :src="require('../assets/images/chennai-int-logo.png')"
              :class="{ 'logo-size': $vuetify.breakpoint.mdAndUp }"
              contain
              :width="$vuetify.breakpoint.smAndDown ? '90%' : '55%'"
            />
          </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <div class="hidden-md-and-down">
            <v-menu
              offset-y
              :nudge-width="1500"
              :open-on-hover="$vuetify.breakpoint.smAndDown ? false : true"
              v-for="(facility, fi) in facilities.slice(0, 4)"
              :key="fi"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text plain v-bind="attrs" v-on="on" class="active">
                  {{ facility.title }}
                  <v-icon right dark> mdi-chevron-down </v-icon>
                </v-btn>
              </template>
              <v-card class="overflow-hidden top-menu">
                <v-row align="center" justify="center">
                  <v-col class="text-center">
                    <h2>{{ facility.title }}</h2>
                  </v-col>
                  <v-col>
                    <v-list class="submenu-padding">
                      <v-list-item
                        v-for="(child, ci) of facility.children"
                        :key="ci"
                        @click="onNavigate(child)"
                      >
                        <v-list-item-title>
                          <v-btn text>
                            {{ child.title }}
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-img
                      :src="require('../assets/images/AIRINDIA.jpg')"
                      class="my-3"
                      contain
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>
          </div>

          <v-btn
            icon
            to="/search"
            class="mt-2 topSearch"
            :class="{ topSearchMobile: $vuetify.breakpoint.smAndDown }"
          >
            <v-icon
              :width="$vuetify.breakpoint.smAndDown ? '50%' : '80%'"
              class="mr-1 mt-1"
              >mdi-magnify</v-icon
            >
            Search
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-title class="ml-15">
          <a :href="aai.url" target="_blank">
            <v-img
              :src="require('../assets/images/logonew.png')"
              contain
              :width="$vuetify.breakpoint.smAndDown ? '94%' : '80%'"
          /></a>
        </v-toolbar-title>
        <v-app-bar-nav-icon @click="onHandleDrawer" />
      </v-toolbar>
    </v-container>
    <!-- Menu Section -->

    <!-- Drawer Section -->
    <v-navigation-drawer
      v-model="isDrawer"
      temporary
      absolute
      right
      class="right-drawer"
      width="400"
      height="100vh"
      color="#00308F"
      dark
      style="position: fixed; bottom: 0; right: 0"
    >
      <v-toolbar flat class="" color="#00308F">
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="onHandleDrawer"
          style="position: relative; right: 4%; top: 4%; z-index: 1"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list class="hamburgerMenu">
        <v-list-group v-for="(facility, fi) in facilities" :key="fi">
          <v-list-item slot="activator">
            <v-list-item-content @click="onNavigate(facility)">
              <v-list-item-title>{{ facility.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(child, ci) in facility.children"
            :key="ci"
            @click="onNavigate(child)"
          >
            <v-list-item-title>{{ child.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Drawer Section -->
  </div>
</template>
<script>
import api from "@/api";
import config from "@/config";
import event from "../event";

export default {
  name: "HeaderComponent",
  data() {
    return {
      weather: {
        temperature: 0,
        description: "-",
      },
      language: "EN",
      facilities: [],
      isDrawer: false,
    };
  },
  computed: {
    notice: {
      get() {
        return this.$store.getters.notice;
      },
    },
    current: {
      get() {
        return this.$store.getters.current;
      },
    },
    aai: {
      get() {
        return config.aai;
      },
    },
  },
  methods: {
    async onLoadFacilities() {
      const { data } = await api.airport.post("/user/facility", {
        page: 1,
        limit: 50,
        sortKey: "sequence",
        sortDirection: "ASC",
        filters: {
          main: true,
          language: this.language,
        },
      });

      if (data?.data) {
        this.facilities = data.data.docs;
      }
    },
    async onLoadWeather() {
      const { data } = await api.weather.post(
        `?q=chennai&APPID=${config.weather.id}`
      );
      if (data) {
        this.weather = {
          temperature: Number(data.main.temp - 273.15).toFixed(1),
          description: data.weather[0]["description"],
        };
      }
    },
    async onChangeLanguage() {
      await this.$store.dispatch("onUpdateLanguage", this.language);
    },
    async onHandleDrawer() {
      this.isDrawer = !this.isDrawer;
    },
    async onNavigate(facility) {
      let name = String(facility.title).toLowerCase().replace(/ /g, "_");
      if (!facility?.children?.length || facility?.template == 8) {
        // if (this.$route.name === "Template")
        // event.$emit("template", facility._id);
        this.$router.push(`/${name}/${facility._id}`).catch(() => {});
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("onUpdateLoader", true);
    await this.$store.dispatch("onSetCurrentDate", { isFulltime: false });
    this.onChangeLanguage();
    await this.onLoadWeather();
    await this.onLoadFacilities();
    await this.$store.dispatch("onUpdateLoader", false);
  },
};
</script>
<style scoped>
.appbar-content {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.active {
  color: #1b63f8;
  font-weight: bold;
}

/* .active3 {
  color: blue;
  font-weight: bold;
} */

.v-btn:not(.v-btn--round).v-size--default {
  height: 100%;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  color: #42b983;
}

.fullwidth {
  width: 100% !important;
}

.top-header {
  width: 100%;
}

/*.menu-select{background-color: red}
.menu-select .v-list-item-title{background-color: green}*/
.top-menu .v-btn.v-size--default {
  font-size: 18px !important;
  font-weight: 400;
  padding: 10px;
}

.top-menu .v-menu__content.theme--light.menuable__content__active {
  width: 100% !important;
  left: 0;
  max-width: 100% !important;
}

.top-menu > .v-menu__content {
  z-index: 0 !important;
}

.v-menu__content {
  box-shadow: none;
}

.top-menu-drop .v-menu__content {
  z-index: 0;
}

.right-menu .v-list-item .v-list-item__title {
  padding: 21px 0;
  font-family: "Satoshi-Bold";
  font-size: 18px;
}

.right-drawer .v-list-item {
  margin-bottom: 0;
}

.right-drawer-submenu .v-list-item {
  margin-bottom: 0px;
  min-height: 30px !important;
}

.right-drawer .v-list-item .v-menu .v-list-item {
  border: 1px solid red;
}

.v-list-item--link::before {
  background-color: none !important;
}

.firstHeader {
  position: relative;
  z-index: 2;
}

.secondHeader {
  z-index: 1;
}

.secondHeader .v-btn:not(.v-btn--round).v-size--default {
  font-family: "Satoshi-Bold";
  font-size: 18px;
}

.text-truncate {
  vertical-align: middle;
  font-size: 7px;
}

.logo-shape {
  z-index: 9;
}
.logo-shape:before {
  position: absolute;
  content: "";
  background-color: #fff;
  box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.2);
  display: block;
  width: 600px;
  border-radius: 125px;
  top: -548px;
  height: 600px;
  left: -204px;
  transform: rotate(45deg);
}

.v-menu__content {
  box-shadow: 0px 25px 50px -12px rgba(16, 24, 40, 0.2) !important;
}

.logo-size {
  margin-left: 45px;
  margin-top: 22px !important;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
.topSearch {
  text-transform: capitalize;
  width: auto !important;
  height: 50px !important;
  padding: 0 10px;
  font-size: 18px;
  color: #374151;
}
.topSearch:focus,
.topSearch:hover {
  background-color: none !important;
}

.topSearchMobile {
  width: 50px !important;
  height: inherit !important;
  font-size: 14px;
  margin-left: 20px;
}

.lang-Drop {
  max-width: 8% !important;
  font-weight: normal;
  color: #fff;
  border: 0;
}

.lang-Drop option {
  color: #1b63f8;
}

/* .v-toolbar__content .v-btn.v-btn--icon.v-size--default, .v-toolbar__extension .v-btn.v-btn--icon.v-size--default { width: 90px;} */
</style>
