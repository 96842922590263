import Vue from "vue";
import VueRouter from "vue-router";
import event from "../event";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("../views/Dashboard"),
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("../views/Feedback"),
  },
  {
    path: "/mlcp",
    name: "MLCP",
    component: () => import("../views/MLCP"),
  },
  {
    path: "/disclaimer",
    name: "Disclaimer",
    component: () => import("../views/Disclaimer"),
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search"),
  },
  {
    path: "/airline/:id",
    name: "Airline",
    component: () => import("../views/Airline/Detail.vue"),
  },
  {
    path: "/flight/:type/:id",
    name: "Flight",
    component: () => import("../views/Flight/Detail.vue"),
  },
  {
    path: "/product/:material/:facility",
    name: "Product",
    component: () => import("../views/Product"),
  },
  {
    path: "/:name/:id",
    name: "Template",
    component: () => import("../views/Template"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
