export default {
  environment: process.env.VUE_APP_ENVIRONMENT,
  insuide: {
    url: process.env.VUE_APP_API_URL,
  },
  airport: {
    url: process.env.VUE_APP_AIRPORT_URL,
  },
  weather: {
    url: process.env.VUE_APP_WEATHER_URL,
    id: process.env.VUE_APP_WEATHER_ID,
  },
  parking: {
    url: process.env.VUE_APP_PARKING_URL,
  },
  poi: {
    url: process.env.VUE_APP_POI_URL,
    app_name: process.env.VUE_APP_POI_APP_NAME,
    token: process.env.VUE_APP_POI_TOKEN,
  },
  aai: {
    url: process.env.VUE_APP_AAI_URL,
  },
  social: {
    facebook: process.env.VUE_APP_FACEBOOK_URL,
    instagram: process.env.VUE_APP_INSTAGRAM_URL,
    youtube: process.env.VUE_APP_YOUTUBE_URL,
    koo: process.env.VUE_APP_KOO_URL,
    twitter: process.env.VUE_APP_TWITTER_URL,
  },
  map: {
    token: process.env.VUE_APP_MAP_ACCESS_TOKEN,
  },
};
