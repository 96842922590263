<template>
  <v-app>
    <v-main>
      <template>
        <Header />
        <OverlayIcon />
        <Loader v-if="loader" />
        <router-view />
        <Footer />
      </template>
    </v-main>
  </v-app>
</template>
<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import Loader from "./components/Loader.vue";
import OverlayIcon from "./components/OverlayIcon.vue";
import event from "./event";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    OverlayIcon,
    Loader,
  },
  data() {
    return {
      loader: false,
    };
  },
  created() {
    event.$on("onUpdateLoader", (data) => {
      this.loader = data;
    });
  },
};
</script>
<style type="text/css">
.v-main {
  overflow-x: hidden;
  overflow-y: scroll;
}

.overlay-image:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.image-border-radius {
  border-radius: 10%;
}
button {
  text-transform: unset !important;
}
.page-title {
  color: #1b63f8 !important;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 3px;
  padding: 2% 0;
}
.page-sub-title h1 {
  margin-bottom: 15px;
  font-size: 40px;
}
.page-sub-title h2 {
  margin-bottom: 15px;
  font-size: 38px;
}
.myslider {
  z-index: 0;
}
.myslider .v-carousel__controls {
  bottom: 10% !important;
}
.mytabs .v-item-group {
  margin-bottom: 40px;
}
.vertical-tab .v-tabs-bar {
  background: none !important;
}
.vertical-tab .v-tab {
  justify-content: left !important;
  color: rgba(255, 255, 255, 0.5) !important;
  margin: 10px 0;
}
.vertical-tab .v-tabs-items {
  width: 35% !important;
  border-radius: 30px !important;
  position: absolute !important;
  right: 0;
  top: 15%;
  padding: 2%;
}
.v-tabs--vertical > .v-tabs-bar .v-tab {
  font-size: 40px;
  font-weight: 500;
}
.v-tabs--vertical .v-list-item__title {
  font-size: 20px !important;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: auto !important;
}
.v-tabs--vertical .v-list-item__subtitle {
  font-size: 16px !important;
}
.v-tabs--vertical .v-list-item {
  margin-bottom: 10px;
}
.vertical-tab-title .mt-10 {
  margin: 20px 0;
}
.vertical-tab {
  margin-top: 50px !important;
}
.vertical-tab .v-tab--active {
  font-size: 40px !important;
  color: rgba(255, 255, 255, 1) !important;
}
.vertical-tab .v-tab--active .v-icon {
  font-size: 28px !important;
  color: rgba(255, 255, 255, 1) !important;
}
.vertical-tab .v-icon {
  margin-right: 20px;
  color: rgba(255, 255, 255, 0.5) !important;
}

.vertical-tab-title {
  .text-h3 {
    font-size: 52px !important;
    font-weight: 700;
  }
  .font-weight-bold {
    letter-spacing: 3px;
    font-size: 14px;
  }
}

.gradient-button {
  background: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
}
.gradient-button a {
  color: #fff !important;
}
.footer-action .v-btn {
  font-size: 14px;
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0;
}
.before-enter {
  opacity: 0;
  transform: translateX(100px);
  transition: all 1s cubic-bezier(0.41, 0.01, 0.57, 1.61);
}

/* 
    If the element intersects with the viewport, the before-enter class is added.
  */
.enter {
  opacity: 1;
  transform: translateX(0px);
}
.v-ripple__container {
  display: none !important;
}
.mytable th {
  font-size: 16px !important;
  font-weight: 500;
  color: #111827 !important;
}
.mytable td {
  font-size: 18px !important;
  font-weight: 700;
}
.mytable.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none !important;
}
.theme--light.v-list-item:focus::before {
  opacity: 0;
}
.mytable .v-data-table__wrapper table tbody tr {
  border-radius: 16px;
}
.mytable .v-data-table__wrapper table {
  border-collapse: separate;
  border-spacing: 0 20px !important;
}
.mytable .v-data-table__wrapper table tbody td:first-child {
  border-left: 2px solid #e5e7eb !important;
  border-right: 0 !important;
  border-top: 2px solid #e5e7eb !important;
  border-bottom: 2px solid #e5e7eb !important;
  border-radius: 24px 0 0 24px;
  padding: 2px 10px;
}
.mytable .v-data-table__wrapper table tbody td:last-child {
  border-left: 0 solid #e5e7eb !important;
  border-right: 2px solid #e5e7eb !important;
  border-top: 2px solid #e5e7eb !important;
  border-bottom: 2px solid #e5e7eb !important;
  border-radius: 0 24px 24px 0;
  padding: 2px 10px;
}

.mytable .v-data-table__wrapper > table > tbody > tr:hover td:first-child {
  border-left: 2px solid #e5e7eb !important;
  border-right: 0 !important;
  border-top: 2px solid #e5e7eb !important;
  border-bottom: 2px solid #e5e7eb !important;
  border-radius: 24px 0 0 24px !important;
  padding: 2px 10px;
}
.mytable .v-data-table__wrapper > table > tbody > tr:hover td:last-child {
  border-left: 0 solid #e5e7eb !important;
  border-right: 2px solid #e5e7eb !important;
  border-top: 2px solid #e5e7eb !important;
  border-bottom: 2px solid #e5e7eb !important;
  border-radius: 0 24px 24px 0 !important;
  padding: 2px 10px;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(
    .v-data-table__empty-wrapper
  ) {
  background: rgb(232, 239, 254) !important;
}

.mytable .v-data-table__wrapper table tbody td {
  border-bottom: 2px solid #e5e7eb !important;
  border-top: 2px solid #e5e7eb !important;
}
.mytable th.text-start.sortable {
  border-bottom: 0 !important;
}

.custom-table-mlccchg .v-data-table__wrapper table thead tr th {
  border-left: thin solid rgba(0, 0, 0, 0.12);
  padding: 0 5px !important;
}
.custom-table-mlccchg .v-data-table__wrapper table tbody tr th:first-child {
  border-left: none;
}
.custom-table-mlccchg .v-data-table__wrapper table thead tr th span {
  font-size: 18px;
}
.custom-table-mlccchg .v-data-table__wrapper table tbody tr td {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
.custom-table-mlccchg .v-data-table__wrapper table tbody tr td:first-child {
  border-left: none;
}

/*TAB STYLE STARTS*/

.mytabs a.v-tab {
  background-color: #e8effe;
  padding: 16px 22px;
  position: relative;
  text-transform: inherit;
  font-family: "Satoshi-Bold";
  font-size: 16px;
  letter-spacing: 0.8px;
}

.mytabs .v-tabs-bar {
  height: auto;
}
.mytabs .v-tabs-slider-wrapper {
  height: 65% !important;
  top: 16%;
  opacity: 0.5;
  padding: 0 10px;
}
.mytabs .v-tabs-slider {
  border-radius: 12px;
  color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.08),
    0px 4px 6px -4px rgba(16, 24, 40, 0.08);
}
.mytabs .v-tab--active {
  color: #000 !important;
}
a.border-radiusbefore.v-tab {
  border-radius: 20px 0 0 20px;
}
a.border-radiusafter.v-tab {
  border-radius: 0px 20px 20px 0px;
}

a.border-radiusboth.v-tab {
  border-radius: 20px 20px 20px 20px;
}

.mytabs {
  .text-h4.text--primary {
    font-size: 32px !important;
    font-family: Satoshi-Bold !important;
  }
  .v-list-item__title {
    font-size: 18px;
    font-weight: 500;
  }
}
/*TAB STYLE ENDS*/

/*image over effect START*/
.imgover .v-image__image {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}

.imgleave .v-image__image {
  transition: 1s ease;
}

.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}
/*image over effect END*/
.custom-slider .v-carousel__controls {
  background: #e8effe !important;
  border-radius: 10px;
}
.custom-slider .theme--dark.v-btn--active:hover::before,
.custom-slider .theme--dark.v-btn--active::before {
  opacity: 0.9;
}
.custom-slider .theme--dark.v-btn:hover::before {
  opacity: 1;
}
.custom-slider .v-btn:before {
  opacity: 0.4;
}

.slick-slide {
  margin: 0px 10px;
}

.slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 0.5;
}

.slick-active {
  opacity: 0.5;
}

.slick-current {
  opacity: 1;
}
.right-menu .v-application a {
  color: #000 !important;
}
.v-btn a.anchor-color {
  color: #0072ff;
}

/* .gradient-button.anchorTag{ height: auto !important; border-radius: 28px; padding:0 !important; } */
.gradient-button.anchorTag {
  border-radius: 28px;
  padding: 0 !important;
}
.gradient-button.anchorTag a {
  padding: 12px 15px !important;
}

.vertical-tab-Two .v-tabs-bar {
  background: none !important;
}
.vertical-tab-Two .v-tab {
  justify-content: left !important;
  font-size: 16px !important;
  color: #374151 !important;
  margin: 2px 0;
  padding: 0 50px 0 20px;
}
.vertical-tab-Two .v-tabs-items {
  width: 50% !important;
  border-radius: 30px !important;
  position: absolute !important;
  right: 0;
  top: 15%;
  padding: 2%;
}

.vertical-tab-Two {
  margin-top: 20px !important;
}
.vertical-tab-Two .v-tab--active,
.vertical-tab-Two .v-tab--hover {
  font-size: 16px !important;
  color: #374151 !important;
  background-color: #e8effe;
  border-radius: 16px;
}

.v-tabs--vertical .v-tabs-bar .v-tab {
  width: max-content;
}

.vertical-tab-Two .v-tabs-slider-wrapper {
  width: 0;
  height: 0 !important;
}
.v-list-item__content:focus,
.v-list-item__content:hover {
  background: none;
}

.lang-Drop.theme--dark.v-text-field .v-input__control .v-input__slot:before {
  border-color: transparent !important;
}
.lang-Drop .v-text-field > .v-input__control > .v-input__slot:after:focus {
  border-color: transparent !important;
}
.lang-Drop .theme--dark.v-label {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
